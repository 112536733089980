import Vue from 'vue'
import { baseUrl } from '../endpoints'
// user/trip-planner/add
const timeout = 10000

export default {
  // add quick plan
  addQuickPlan (body) {
    return Vue.axios.post(baseUrl + 'user/trip-planner/add', body, {
      timeout: timeout,
      headers: { Accept: 'application/json', 'Content-type': 'application/json' }
    })
  },
  // list plans
  listPlans () {
    return Vue.axios.get(baseUrl + 'user/trip-planner/list',
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // get plan
  showPlan (id) {
    return Vue.axios.get(baseUrl + `user/trip-planner/${id}/show`,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // get plan announce
  planAnnonce (id) {
    return Vue.axios.get(baseUrl + `user/trip-planner/${id}/details`,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // delete plan
  deletePlan (id) {
    return Vue.axios.delete(baseUrl + `user/trip-planner/${id}`,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // add plan basic info
  addPlanBasicInfo (body) {
    return Vue.axios.post(baseUrl + 'user/trip-planner/add',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // update plan basic info
  updatePlanBasicInfo (body, id) {
    return Vue.axios.post(baseUrl + `user/trip-planner/${id}/edit`,
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // add plan stop info
  addPlanStop (body) {
    return Vue.axios.post(baseUrl + 'user/trip-planner/add/stop',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // add plan stop info
  deletePlanStop (body, id) {
    return Vue.axios.post(baseUrl + `user/trip-planner/${id}/delete/stop`,
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // update Plan stop
  updatePlanStop (body, id) {
    return Vue.axios.post(baseUrl + `user/trip-planner/${id}/edit/stop`,
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // list transportations
  listTransportations () {
    return Vue.axios.get(baseUrl + 'user/trip-planner/travel_by',
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // list stays
  listStays () {
    return Vue.axios.get(baseUrl + 'user/trip-planner/stay_in',
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  }
}

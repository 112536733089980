<template>
    <v-card max-width="90%" elevation="0" :outlined="$route.name === 'planAdd'" class="pa-10 mx-auto rounded-lg">
        <v-card-title small class="primary--text text-bold d-flex justify-space-between">
            <h3 v-text="$route.name !== 'planAdd' ? 'Update Plan' : 'Post a Plan'"></h3>
            <v-btn v-if="$route.name === 'planAdd'" tile elevation="0" color="primary" large class="mb-5" @click="$router.push({name: 'plansList'})"><v-icon left>mdi-keyboard-return</v-icon> Return TO plans</v-btn>
        </v-card-title>
        <v-text-field
        v-model="title"
        class="rounded-lg"
        :rules="[v => !!v || 'Please enter title']"
        outlined
        prepend-inner-icon="mdi-airplane-takeoff"
        placeholder="Trip Name"
        :readonly="readonly"
        @input="$emit('setTitle', title)"
        ></v-text-field>

        <v-textarea
        v-model="desc"
        class="rounded-lg"
        :rules="[v => !!v || 'Please enter description']"
        outlined
        placeholder="Trip Description"
        :readonly="readonly"
        @input="$emit('setDesc', desc)"
        ></v-textarea>
    </v-card>
</template>

<script>
export default {
  props: ['readonly', 'data'],
  data () {
    return {
      title: '',
      desc: ''
    }
  },
  created () {
    if (this.$route.name !== 'planAdd') {
      this.title = this.data.name
      this.desc = this.data.description
    }
  }
}
</script>

<style>
</style>
